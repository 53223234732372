export const addBulkVitalReadings = /* GraphQL */ `
  mutation AddBulkVitalReadings(
    $deviceId: ID
    $familyId: ID!
    $hardwareId: ID!
    $vitalReadings: [VitalReadingInput!]!
    $vitalTypeId: ID!
  ) {
    addBulkVitalReadings(
      deviceId: $deviceId
      familyId: $familyId
      hardwareId: $hardwareId
      vitalReadings: $vitalReadings
      vitalTypeId: $vitalTypeId
    ) {
      latestReading {
        contactId
        createdAt
        deviceId
        familyId
        hardwareId
        isManual
        readAt
        reading
        readingId
        vitalTypeId
      }
      readingCount
      vitalTypeId
      vitalTypeName
    }
  }
`;
export const addDeviceToken = /* GraphQL */ `
  mutation AddDeviceToken($deviceToken: String!) {
    addDeviceToken(deviceToken: $deviceToken)
  }
`;
export const addMediaToAlbum = /* GraphQL */ `
  mutation AddMediaToAlbum($albumId: ID!, $mediaIds: [ID!]!) {
    addMediaToAlbum(albumId: $albumId, mediaIds: $mediaIds) {
      albumId
      contactId
      createdAt
      familyId
      memories {
        nextToken
      }
      title
      updatedAt
    }
  }
`;
export const addVitalReading = /* GraphQL */ `
  mutation AddVitalReading(
    $deviceId: ID
    $familyId: ID!
    $hardwareId: ID!
    $vitalReading: VitalReadingInput!
    $vitalTypeId: ID!
  ) {
    addVitalReading(
      deviceId: $deviceId
      familyId: $familyId
      hardwareId: $hardwareId
      vitalReading: $vitalReading
      vitalTypeId: $vitalTypeId
    ) {
      latestReading {
        contactId
        createdAt
        deviceId
        familyId
        hardwareId
        isManual
        readAt
        reading
        readingId
        vitalTypeId
      }
      readingCount
      vitalTypeId
      vitalTypeName
    }
  }
`;
export const adminAddSeniorToFamily = /* GraphQL */ `
  mutation AdminAddSeniorToFamily($familyId: ID!, $senior: SeniorInput!) {
    adminAddSeniorToFamily(familyId: $familyId, senior: $senior) {
      activeStatus
      config {
        champConnectId
        medicationDisclaimer
      }
      contactId
      createdAt
      email
      familyId
      familyName
      givenName
      invite {
        createdAt
        createdBy
        expireAt
        familyName
        givenName
        status
        username
      }
      isEmergencyContact
      isFamilyOrFriend
      name
      note
      phone
      picture {
        bucket
        key
        region
        uploadUrl
        url
      }
      relationship
      role
      status
      updatedAt
      viewMedication
      viewReminders
      viewVitals
    }
  }
`;
export const adminCreateFamily = /* GraphQL */ `
  mutation AdminCreateFamily($familyOwner: FamilyOwnerInput!) {
    adminCreateFamily(familyOwner: $familyOwner) {
      contactId
      createdAt
      familyId
      familyName
      ownerId
      role
      seniorIds
      updatedAt
    }
  }
`;
export const checkStatus = /* GraphQL */ `
  mutation CheckStatus($familyId: ID!) {
    checkStatus(familyId: $familyId)
  }
`;
export const connect = /* GraphQL */ `
  mutation Connect {
    connect {
      activeStatus
      contactId
      familyId
      lastActiveTime
    }
  }
`;
export const createAlbum = /* GraphQL */ `
  mutation CreateAlbum($album: AlbumInput, $familyId: ID!) {
    createAlbum(album: $album, familyId: $familyId) {
      albumId
      contactId
      createdAt
      familyId
      memories {
        nextToken
      }
      title
      updatedAt
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $appointment: AppointmentInput!
    $contactId: ID
    $familyId: ID!
  ) {
    createAppointment(
      appointment: $appointment
      contactId: $contactId
      familyId: $familyId
    ) {
      appointmentDate
      appointmentId
      appointmentName
      appointmentStartDate
      appointmentType
      contactId
      createdAt
      createdBy
      endTime
      familyId
      guests
      guestsMetaData {
        contactId
        givenName
      }
      isAllDay
      location
      note
      reccuranceType
      startTime
      taskDescription
      updatedAt
      updatedBy
    }
  }
`;
export const createChampUser = /* GraphQL */ `
  mutation CreateChampUser($contactId: ID!, $familyId: ID!) {
    createChampUser(contactId: $contactId, familyId: $familyId) {
      champConnectId
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $aliasType: AliasType!
    $contact: ContactInput!
    $contactId: ID
    $familyId: ID!
  ) {
    createContact(
      aliasType: $aliasType
      contact: $contact
      contactId: $contactId
      familyId: $familyId
    ) {
      activeStatus
      config {
        champConnectId
        medicationDisclaimer
      }
      contactId
      createdAt
      email
      familyId
      familyName
      givenName
      invite {
        createdAt
        createdBy
        expireAt
        familyName
        givenName
        status
        username
      }
      isEmergencyContact
      isFamilyOrFriend
      name
      note
      phone
      picture {
        bucket
        key
        region
        uploadUrl
        url
      }
      relationship
      role
      status
      updatedAt
      viewMedication
      viewReminders
      viewVitals
    }
  }
`;
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment(
    $contactId: ID
    $equipment: EquipmentInput!
    $familyId: ID!
  ) {
    createEquipment(
      contactId: $contactId
      equipment: $equipment
      familyId: $familyId
    ) {
      addedDate
      connectedDevices
      contactId
      createdAt
      createdBy
      deviceName
      deviceType
      equipmentId
      familyId
      familyName
      givenName
      pictureUrl
      status {
        battery
        bluetooth
        wifi
      }
    }
  }
`;
export const createLink = /* GraphQL */ `
  mutation CreateLink($description: String, $familyId: ID!, $link: String!) {
    createLink(description: $description, familyId: $familyId, link: $link) {
      createdAt
      createdBy
      description
      familyId
      link
      linkId
    }
  }
`;
export const createMedication = /* GraphQL */ `
  mutation CreateMedication($familyId: ID!, $medication: MedicationInput!) {
    createMedication(familyId: $familyId, medication: $medication) {
      additionalDetails
      contactId
      createdAt
      createdBy
      dosageList {
        detail
        id
        quantity
        time
      }
      endDate
      familyId
      isRemind
      medicationId
      medicationName
      medicationTypeId
      startDate
      updatedBy
    }
  }
`;
export const deleteAlbum = /* GraphQL */ `
  mutation DeleteAlbum($albumId: ID!, $contactId: ID) {
    deleteAlbum(albumId: $albumId, contactId: $contactId) {
      albumId
      contactId
      createdAt
      familyId
      memories {
        nextToken
      }
      title
      updatedAt
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment($appointmentId: ID!, $familyId: ID!) {
    deleteAppointment(appointmentId: $appointmentId, familyId: $familyId)
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact($contactId: ID!, $familyId: ID!) {
    deleteContact(contactId: $contactId, familyId: $familyId)
  }
`;
export const deleteContacts = /* GraphQL */ `
  mutation DeleteContacts($contactIds: [ID!]!, $familyId: ID!) {
    deleteContacts(contactIds: $contactIds, familyId: $familyId)
  }
`;
export const deleteLink = /* GraphQL */ `
  mutation DeleteLink($familyId: ID!, $linkId: ID!) {
    deleteLink(familyId: $familyId, linkId: $linkId) {
      createdAt
      createdBy
      description
      familyId
      link
      linkId
    }
  }
`;
export const deleteMedication = /* GraphQL */ `
  mutation DeleteMedication(
    $contactId: ID
    $familyId: ID!
    $medicationId: ID!
  ) {
    deleteMedication(
      contactId: $contactId
      familyId: $familyId
      medicationId: $medicationId
    ) {
      contactId
      endDate
      familyId
      medicationId
      medicationName
      startDate
    }
  }
`;
export const deleteMemories = /* GraphQL */ `
  mutation DeleteMemories($contactId: ID, $mediaIds: [ID!]!) {
    deleteMemories(contactId: $contactId, mediaIds: $mediaIds)
  }
`;
export const disconnect = /* GraphQL */ `
  mutation Disconnect($familyId: ID!) {
    disconnect(familyId: $familyId) {
      activeStatus
      contactId
      familyId
      lastActiveTime
    }
  }
`;
export const disconnected = /* GraphQL */ `
  mutation Disconnected($contactId: ID!, $familyId: ID!) {
    disconnected(contactId: $contactId, familyId: $familyId) {
      activeStatus
      contactId
      familyId
      lastActiveTime
    }
  }
`;
export const forgetPassword = /* GraphQL */ `
  mutation ForgetPassword($userName: String!) {
    forgetPassword(userName: $userName)
  }
`;
export const joinFamily = /* GraphQL */ `
  mutation JoinFamily($inviteCode: ID!) {
    joinFamily(inviteCode: $inviteCode) {
      contactId
      createdAt
      createdBy
      expireAt
      familyId
      familyName
      givenName
      inviteCode
      status
      username
    }
  }
`;
export const registerDevice = /* GraphQL */ `
  mutation RegisterDevice($device: DeviceInput!, $familyId: ID!) {
    registerDevice(device: $device, familyId: $familyId) {
      contactId
      createdAt
      deviceId
      deviceName
      familyId
      hardwareId
      lastActivityAt
      updatedAt
      vitalTypeIds
    }
  }
`;
export const removeContactPicture = /* GraphQL */ `
  mutation RemoveContactPicture($contactId: ID!, $familyId: ID!) {
    removeContactPicture(contactId: $contactId, familyId: $familyId) {
      activeStatus
      config {
        champConnectId
        medicationDisclaimer
      }
      contactId
      createdAt
      email
      familyId
      familyName
      givenName
      invite {
        createdAt
        createdBy
        expireAt
        familyName
        givenName
        status
        username
      }
      isEmergencyContact
      isFamilyOrFriend
      name
      note
      phone
      picture {
        bucket
        key
        region
        uploadUrl
        url
      }
      relationship
      role
      status
      updatedAt
      viewMedication
      viewReminders
      viewVitals
    }
  }
`;
export const resendContactInvite = /* GraphQL */ `
  mutation ResendContactInvite($contactId: ID!, $familyId: ID!) {
    resendContactInvite(contactId: $contactId, familyId: $familyId) {
      contactId
      createdAt
      createdBy
      expireAt
      familyId
      familyName
      givenName
      status
      username
    }
  }
`;
export const resetPassword = /* GraphQL */ `
  mutation ResetPassword($password: String!, $token: ID!, $userName: String!) {
    resetPassword(password: $password, token: $token, userName: $userName)
  }
`;
export const sendMediaMessage = /* GraphQL */ `
  mutation SendMediaMessage(
    $conversationId: ID!
    $data: MediaMessageInput!
    $sentBy: ID!
  ) {
    sendMediaMessage(
      conversationId: $conversationId
      data: $data
      sentBy: $sentBy
    ) {
      conversationId
      createdAt
      data
      messageId
      receivedAt
      receivedBy
      sentAt
      sentBy
      tempId
      text
      type
    }
  }
`;
export const sendMessageMutation = /* GraphQL */ `
  mutation SendMessage($conversationId: ID!, $message: MessageInput) {
    sendMessage(conversationId: $conversationId, message: $message) {
      conversationId
      createdAt
      data
      messageId
      receivedAt
      receivedBy
      sentAt
      sentBy
      tempId
      text
      type
    }
  }
`;
export const setConfig = /* GraphQL */ `
  mutation SetConfig($deviceToken: String, $zoneInfo: String) {
    setConfig(deviceToken: $deviceToken, zoneInfo: $zoneInfo)
  }
`;
export const setConfiguration = /* GraphQL */ `
  mutation SetConfiguration($configuration: ConfigurationInput) {
    setConfiguration(configuration: $configuration) {
      locale
      zoneInfo
    }
  }
`;
export const setHeartBeat = /* GraphQL */ `
  mutation SetHeartBeat($familyId: ID!) {
    setHeartBeat(familyId: $familyId) {
      activeStatus
      contactId
      familyId
      lastActiveTime
    }
  }
`;
export const setPassword = /* GraphQL */ `
  mutation SetPassword($inviteCode: ID!, $password: String!) {
    setPassword(inviteCode: $inviteCode, password: $password)
  }
`;
export const setUploadStatus = /* GraphQL */ `
  mutation SetUploadStatus($mediaIds: [ID!]!, $uploadId: ID!) {
    setUploadStatus(mediaIds: $mediaIds, uploadId: $uploadId) {
      chat {
        conversationId
        createdAt
        data
        messageId
        receivedAt
        receivedBy
        sentAt
        sentBy
        tempId
        text
        type
      }
      conversationId
      memory {
        bucket
        key
        mediaId
        url
      }
    }
  }
`;
export const shareMemories = /* GraphQL */ `
  mutation ShareMemories($contactIds: [ID]!, $familyId: ID!, $mediaIds: [ID]!) {
    shareMemories(
      contactIds: $contactIds
      familyId: $familyId
      mediaIds: $mediaIds
    )
  }
`;
export const unRegisterDevice = /* GraphQL */ `
  mutation UnRegisterDevice($deviceId: ID!) {
    unRegisterDevice(deviceId: $deviceId) {
      contactId
      createdAt
      deviceId
      deviceName
      familyId
      hardwareId
      lastActivityAt
      updatedAt
      vitalTypeIds
    }
  }
`;
export const updateAlbum = /* GraphQL */ `
  mutation UpdateAlbum($album: UpdateAlbumInput, $albumId: ID!) {
    updateAlbum(album: $album, albumId: $albumId) {
      albumId
      contactId
      createdAt
      familyId
      memories {
        nextToken
      }
      title
      updatedAt
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $appointment: AppointmentUpdateInput!
    $appointmentId: ID!
    $contactId: ID
    $familyId: ID!
  ) {
    updateAppointment(
      appointment: $appointment
      appointmentId: $appointmentId
      contactId: $contactId
      familyId: $familyId
    ) {
      appointmentDate
      appointmentId
      appointmentName
      appointmentStartDate
      appointmentType
      contactId
      createdAt
      createdBy
      endTime
      familyId
      guests
      guestsMetaData {
        contactId
        givenName
      }
      isAllDay
      location
      note
      reccuranceType
      startTime
      taskDescription
      updatedAt
      updatedBy
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $contact: UpdateContactInput
    $contactId: ID!
    $familyId: ID!
  ) {
    updateContact(
      contact: $contact
      contactId: $contactId
      familyId: $familyId
    ) {
      activeStatus
      config {
        champConnectId
        medicationDisclaimer
      }
      contactId
      createdAt
      email
      familyId
      familyName
      givenName
      invite {
        createdAt
        createdBy
        expireAt
        familyName
        givenName
        status
        username
      }
      isEmergencyContact
      isFamilyOrFriend
      name
      note
      phone
      picture {
        bucket
        key
        region
        uploadUrl
        url
      }
      relationship
      role
      status
      updatedAt
      viewMedication
      viewReminders
      viewVitals
    }
  }
`;
export const updateContactPicture = /* GraphQL */ `
  mutation UpdateContactPicture($contactId: ID!, $familyId: ID!, $key: String) {
    updateContactPicture(
      contactId: $contactId
      familyId: $familyId
      key: $key
    ) {
      bucket
      key
      region
      uploadUrl
      url
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($deviceId: ID!, $deviceName: String!) {
    updateDevice(deviceId: $deviceId, deviceName: $deviceName) {
      contactId
      createdAt
      deviceId
      deviceName
      familyId
      hardwareId
      lastActivityAt
      updatedAt
      vitalTypeIds
    }
  }
`;
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment(
    $contactId: ID
    $equipment: EquipmentUpdateInput!
    $equipmentId: ID!
    $familyId: ID!
  ) {
    updateEquipment(
      contactId: $contactId
      equipment: $equipment
      equipmentId: $equipmentId
      familyId: $familyId
    ) {
      addedDate
      connectedDevices
      contactId
      createdAt
      createdBy
      deviceName
      deviceType
      equipmentId
      familyId
      familyName
      givenName
      pictureUrl
      status {
        battery
        bluetooth
        wifi
      }
    }
  }
`;
export const updateIntakeHistory = /* GraphQL */ `
  mutation UpdateIntakeHistory(
    $contactId: String
    $dosageId: String!
    $dosageTakenDate: String!
    $familyId: ID!
    $hasTaken: Boolean!
    $medicationId: String!
  ) {
    updateIntakeHistory(
      contactId: $contactId
      dosageId: $dosageId
      dosageTakenDate: $dosageTakenDate
      familyId: $familyId
      hasTaken: $hasTaken
      medicationId: $medicationId
    ) {
      contactId
      dosageTakenDate
      familyId
      medicationId
      takenDosageList {
        detail
        hasPrompted
        hasTaken
        id
        quantity
        time
      }
    }
  }
`;
export const updateMedication = /* GraphQL */ `
  mutation UpdateMedication(
    $familyId: ID!
    $medication: UpdateMedicationInput!
    $medicationId: ID!
  ) {
    updateMedication(
      familyId: $familyId
      medication: $medication
      medicationId: $medicationId
    ) {
      additionalDetails
      contactId
      createdAt
      createdBy
      dosageList {
        detail
        id
        quantity
        time
      }
      endDate
      familyId
      isRemind
      medicationId
      medicationName
      medicationTypeId
      startDate
      updatedBy
    }
  }
`;
export const uploadMediaQuery = /* GraphQL */ `
  mutation UploadMedia(
    $familyId: ID!
    $mediaCount: Int
    $metadata: UploadMetadataInput
    $type: UploadType!
  ) {
    uploadMedia(
      familyId: $familyId
      mediaCount: $mediaCount
      metadata: $metadata
      type: $type
    ) {
      contactId
      createdAt
      familyId
      media {
        bucket
        key
        mediaId
        uploadUrl
      }
      mediaCount
      type
      uploadId
    }
  }
`;
export const verifyResetCode = /* GraphQL */ `
  mutation VerifyResetCode($resetCode: String!, $userName: String!) {
    verifyResetCode(resetCode: $resetCode, userName: $userName) {
      token
    }
  }
`;
export const updateMemoryStatus = /* GraphQL */ `
  mutation UpdateMemoryStatus($familyId: ID!) {
    updateMemoryStatus(familyId: $familyId)
  }
`;

export const downloadMemories = /* GraphQL */ `
  mutation downloadMemories( $memories: [String], $bucket: String ) {
    downloadMemories( memories: $memories, bucket: $bucket ) {
      url
    }
  }
`;

export const sendActivityReminder = /* GraphQL */ `
  mutation sendActivityReminderNotification( $familyId: ID!, $contactId: ID! ) {
    sendActivityReminderNotification( familyId: $familyId, contactId: $contactId ) 
  }
`;

export const sendDashboardChangeReminder = /* GraphQL */ `
  mutation sendReminderDashboardChange( $familyId: ID!, $contactId: ID! ) {
    sendReminderDashboardChange( familyId: $familyId, contactId: $contactId ) {
      contactId
    }
  }
`;

export const sendDashboardBuildNotification = /* GraphQL */ `
  mutation sendDashboardNotification( $emails: [String]! , $application:String) {
    sendDashboardNotification( emails: $emails , application: $application ){
      emails
    }
  }
`;

export const sendDashboardUpdateNotification = /* GraphQL */ `
  mutation sendDashboardUpdateNotification( $emails: [String]! , $application:String , $action:String , $languauge:String) {
    sendDashboardUpdateNotification( emails: $emails , application: $application , action: $action ,languauge: $languauge ){
      emails
    }
  }
`;

export const resetpin=/* GraphQL */ `
mutation ( $email:String!) {
  resetPin( email:$email ) 
}
`;
export const createKioskNotification = /* GraphQL */ `
mutation createKioskNotification( 
  $messageId:ID,
  $message:String!,
  $startTime:String,
  $companyId:Int,
  $endTime:String,
  $users:[ID] ) {
  createKioskNotification( messageId:$messageId, message:$message, startTime:$startTime, companyId:$companyId, endTime:$endTime, users:$users) {
    creationTime
    endTime
    message
    messageId
    startTime
    companyId
    users
  }
}
`;
export const deleteKioskNotification = /* GraphQL */ `
mutation deleteKioskNotification( 
  $messageId:String!, 
  $creationTime:String!,
  $users:[ID] ) {
  deleteKioskNotification( messageId:$messageId, creationTime: $creationTime, users: $users ) {
    creationTime
    endTime
    message
    messageId
    startTime
    users
  }
}
`;
export const updateKioskNotification = /* GraphQL */ `
mutation updateKioskNotification( 
  $messageId:ID!,
  $message:String,
  $startTime:String,
  $companyId:Int,
  $endTime:String,
  $creationTime:String!,
  $users:[ID]) {
  updateKioskNotification(messageId: $messageId, startTime: $startTime, companyId:$companyId ,endTime: $endTime, message: $message, users: $users, creationTime: $creationTime) {
    creationTime
    endTime
    message
    messageId
    startTime
    companyId
    users
  }
}
`;
export const sendRssBuildNotification = /* GraphQL */ `
  mutation sendRssFeedChange( $emails: [String]!, $rssId : ID!) {
    sendRssFeedChange( emails: $emails, rssId : $rssId) {
      emails,
      rssId
    }
  }
`;
export const sendRssFeedDeleteNotification = /* GraphQL */ `
mutation sendRssFeedDeleteNotification( $emails: [String]!, $rssId : ID!) {
  sendRssFeedDeleteNotification( emails: $emails, rssId : $rssId) {
    emails,
    rssId
  }
}
`;
export const sendThirdPartyUpdateNotification = /* GraphQL */ `
mutation sendThirdPartyChange( $emails: [String]!) {
  sendThirdPartyChange( emails: $emails) {
    emails
  }
}
`;
export const sendThirdPartyDeleteNotification = /* GraphQL */ `
mutation sendThirdPartyDelete( $emails: [String]!) {
  sendThirdPartyDelete( emails: $emails) {
    emails
  }
}
`;